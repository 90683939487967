import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class FaqV2 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imagealt = 'image';

        return (
            <section className="faq-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="faq-two__content">
                                <div className="block-title-two text-left">
                                    <p><FormattedMessage id='faqporque'/></p>
                                </div>{/* /.block-title-two */}
                                <div className="accrodion-grp" data-grp-name="faq-two-accrodion">
                                    <div className="accrodion active">
                                        <div className="accrodion-title">
                                            <h4><FormattedMessage id='faqhead1'/></h4>
                                        </div>
                                        <div className="accrodion-content">
                                            <div className="inner">
                                                <p><FormattedMessage id='faqheaddesc1'/></p>
                                            </div>{/* /.inner */}
                                        </div>
                                    </div>
                                    <div className="accrodion ">
                                        <div className="accrodion-title">
                                            <h4><FormattedMessage id='faqhead2'/></h4>
                                        </div>
                                        <div className="accrodion-content">
                                            <div className="inner">
                                                <p><FormattedMessage id='faqheaddesc2'/></p>
                                            </div>{/* /.inner */}
                                        </div>
                                    </div>
                                    <div className="accrodion ">
                                        <div className="accrodion-title">
                                            <h4><FormattedMessage id='faqhead3'/></h4>
                                        </div>
                                        <div className="accrodion-content">
                                            <div className="inner">
                                                <p><FormattedMessage id='faqheaddesc3'/></p>
                                            </div>{/* /.inner */}
                                        </div>
                                    </div>
                                </div>
                                {/* <a href="#" className="thm-btn faq-two__btn">Leer Más <i className="fa fa-angle-double-right" /></a>/.thm-btn faq-two__btn */}
                            </div>{/* /.faq-two__content */}
                        </div>{/* /.col-lg-6 */}
                        <div className="col-lg-6 d-flex justify-content-center">
                            <div className="faq-two__image">
                                <img src={publicUrl + "assets/images/resources/faq-2-image.png"} alt={imagealt} />
                            </div>{/* /.faq-two__image */}
                        </div>{/* /.col-lg-6 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </section>
        );
    }
}

export default (FaqV2);
