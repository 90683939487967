import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Service from './section-components/service-v5';
import FaqV2 from './section-components/faq-v2';
import Footer from './global-components/footer';

import { FormattedMessage } from 'react-intl';

const ServiceV1 = () => {

    return (
        <div>
            <Navbar />
            <PageHeader headertitle={<FormattedMessage id='servicehead'/>} /> 
            <Service />
            <FaqV2 />
            <Footer />
        </div>
    );
}

export default ServiceV1;
