import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class WhyChooseUs extends Component {

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return <section className="service-one go-top">
      <div className="container">
        <div className="block-title text-center">
          <h3><FormattedMessage id='porque' /></h3>
          <div className="block-title__line" />{/* /.block-title__line */}
        </div>{/* /.block-title */}
        <div className="row high-gutter  go-top">
          <div className="col-lg-4">
            <div className="service-one__single">
              <div className="service-one__top">
                <div className="service-one__icon">
                  <img src={publicUrl + "assets/images/shapes/service-i-1.png"} alt={imagealt} />
                </div>{/* /.service-one__icon */}
                <div className="service-one__top-content">
                  <h3><FormattedMessage id='entre1' /></h3>
                  {/* <p>Tenga éxito con VanRen Consultoría</p> */}
                </div>{/* /.service-one__top-content */}
              </div>{/* /.service-one__top */}
              <h4><FormattedMessage id='ent1' /></h4>
              <p><FormattedMessage id='text1' /></p>
            </div>{/* /.service-one__single */}
          </div>{/* /.col-lg-4 */}
          <div className="col-lg-4">
            <div className="service-one__single">
              <div className="service-one__top">
                <div className="service-one__icon">
                  <img src={publicUrl + "assets/images/shapes/service-i-2.png"} alt={imagealt} />
                </div>{/* /.service-one__icon */}
                <div className="service-one__top-content">
                  <h3><FormattedMessage id='entre2' /></h3>
                  {/* <p>Optimización de Ventas</p> */}
                  <br></br>
                  <br></br>
                </div>{/* /.service-one__top-content */}
              </div>{/* /.service-one__top */}
              <h4><FormattedMessage id='ent2' /></h4>
              <p><FormattedMessage id='text2' /></p>
            </div>{/* /.service-one__single */}
          </div>{/* /.col-lg-4 */}
          <div className="col-lg-4">
            <div className="service-one__single">
              <div className="service-one__top">
                <div className="service-one__icon">
                  <img src={publicUrl + "assets/images/shapes/service-i-3.png"} alt={imagealt} />
                </div>{/* /.service-one__icon */}
                <div className="service-one__top-content">
                  <h3><FormattedMessage id='entre3' /></h3>
                  {/* <p>Potencial Comercial</p> */}
                  <br></br>
                </div>{/* /.service-one__top-content */}
              </div>{/* /.service-one__top */}
              <h4><FormattedMessage id='ent3' /></h4>
              <p><FormattedMessage id='text3' /></p>
            </div>{/* /.service-one__single */}
          </div>{/* /.col-lg-4 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>

  }
}

export default WhyChooseUs