import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

class Banner extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="banner-one go-top" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/banner-bg-1-1.png)'}}>
				  <img src={publicUrl+"assets/images/resources/p1.jpg"} alt={ imagealt } className="banner-one__moc" />
				  <div className="container-fluid">
				    <div className="row">
				      <div className="col-lg-8">
				        <div className="banner-one__content">
				          <h3><span><FormattedMessage id='estrategias1' /></span> <br /><FormattedMessage id='estrategias2' /><br /> <FormattedMessage id='estrategias3' /> <em><FormattedMessage id='estrategias4' /></em></h3>
				          <p><FormattedMessage id='desc1' /></p>
				          <Link to="/service" className="thm-btn banner-one__btn"><FormattedMessage id='discoverNow' /><i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn */}
				        </div>
				      </div>
				    </div>
				  </div>
				</section>
        }
}

export default Banner