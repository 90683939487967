import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';
import Service from './components/service';
import Contact from './components/contact';

import { LangProvider } from './context/langContext';


class Root extends Component {
	render() {
		return (
			<LangProvider>
				<HashRouter basename="/">
					<div>
						<Switch>
							<Route exact path="/" component={HomeV1} />
							<Route path="/service" component={Service} />
							<Route path="/contact" component={Contact} />
						</Switch>
					</div>
				</HashRouter>
			</LangProvider>
		)
	}
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('vanren'));
