import React, { useState } from "react";
import traduccionesIngles from '../lang/en-US.json';
import traduccionesEspañol from '../lang/es-MX.json';

import { IntlProvider } from "react-intl";

const langContext = React.createContext();

const LangProvider = ({ children }) => {

    const [traducciones, establecerTraducciones] = useState(traduccionesEspañol);
    const [locale, establecerLocale] = useState('es-MX');

    const establecerLenguage = (lenguage) => {
        switch (lenguage) {
            case 'es-MX':
                establecerTraducciones(traduccionesEspañol);
                establecerLocale('es-MX');
                break;
            case 'en-US':
                establecerTraducciones(traduccionesIngles);
                establecerLocale('en-US');
                break;
            default:
                establecerTraducciones(traduccionesEspañol);
                establecerLocale('es-MX');
                break;
        }
    }

    return (
        <langContext.Provider value={{ establecerLenguage: establecerLenguage }}>
            <IntlProvider locale={locale} messages={traducciones}>
                {children}
            </IntlProvider>
        </langContext.Provider>
    );
}

export { LangProvider, langContext };