import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


class ServiceV5 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imagealt = 'image';

        return (
            <section className="service-two service-two__service-page go-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="service-two__block">
                                <div className="block-title-two text-left">
                                    <p><FormattedMessage id='servicess'/></p>
                                    <h3><FormattedMessage id='servicehead1'/><br /><FormattedMessage id='servicehead2'/></h3>
                                </div>{/* /.block-title-two */}
                                <p><FormattedMessage id='servicedesc'/></p>
                                <ul className="list-unstyled video-one__list">
                                    <li><i className="far fa-check" /><FormattedMessage id='servicelist1'/></li>
                                    <li><i className="far fa-check" /><FormattedMessage id='servicelist2'/></li>
                                    <li><i className="far fa-check" /><FormattedMessage id='servicelist3'/></li>
                                    <li><i className="far fa-check" /><FormattedMessage id='servicelist4'/></li>
                                </ul>{/* /.list-unstyled video-one__list */}
                            </div>{/* /.service-two__block */}
                        </div>{/* /.col-lg-5 */}
                        <div className="col-lg-7">
                            <div className="service-two__box-wrap">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="service-two__single js-tilt">
                                            <div className="service-two__single-image" />
                                            <div className="service-two__single-inner">
                                                <i className="fal fa-hand-holding-usd" />
                                                <h3><FormattedMessage id='servicedetails1'/></h3>
                                                <p><FormattedMessage id='servicedetailsdesc1'/></p>
                                            </div>{/* /.service-two__single-inner */}
                                        </div>{/* /.service-two__single */}
                                    </div>{/* /.col-md-6 */}
                                    <div className="col-md-6">
                                        <div className="service-two__single js-tilt">
                                            <div className="service-two__single-image" />
                                            <div className="service-two__single-inner">
                                                <i className="fal fa-umbrella-beach" />
                                                <h3><FormattedMessage id='servicedetails2'/></h3>
                                                <p><FormattedMessage id='servicedetailsdesc2'/></p>
                                            </div>{/* /.service-two__single-inner */}
                                        </div>{/* /.service-two__single */}
                                    </div>{/* /.col-md-6 */}
                                    <div className="col-md-6">
                                        <div className="service-two__single js-tilt">
                                            <div className="service-two__single-image" />
                                            <div className="service-two__single-inner">
                                                <i className="fal fa-paper-plane" />
                                                <h3><FormattedMessage id='servicedetails3'/></h3>
                                                <p><FormattedMessage id='servicedetailsdesc3'/></p>
                                            </div>{/* /.service-two__single-inner */}
                                        </div>{/* /.service-two__single */}
                                    </div>{/* /.col-md-6 */}
                                    <div className="col-md-6">
                                        <div className="service-two__single js-tilt">
                                            <div className="service-two__single-image" />
                                            <div className="service-two__single-inner">
                                                <i className="fal fa-chart-network" />
                                                <h3><FormattedMessage id='servicedetails4'/></h3>
                                                <p><FormattedMessage id='servicedetailsdesc4'/></p>
                                            </div>{/* /.service-two__single-inner */}
                                        </div>{/* /.service-two__single */}
                                    </div>{/* /.col-md-6 */}
                                </div>{/* /.row */}
                            </div>{/* /.service-two__box-wrap */}
                        </div>{/* /.col-lg-7 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </section>
        );
    }
}

export default (ServiceV5);
