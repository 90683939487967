import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import mx from '../../img/mexico.png';
import us from '../../img/usa.png';

import { langContext } from '../../context/langContext';

const Navbar = () => {
    const idioma = useContext(langContext);

    return (
        <header className="site-header-one go-top">
            <nav className="main-nav__one stricky">
                <div className="container-fluid">
                    <div className="main-nav__logo-box">
                        <a onClick={() => idioma.establecerLenguage('es-MX')} onTouchEnd={() => idioma.establecerLenguage('es-MX')}><img src={mx} alt='ES' /></a>
                        <a onClick={() => idioma.establecerLenguage('en-US')} onTouchEnd={() => idioma.establecerLenguage('en-US')}><img src={us} alt='EN' /></a>
                        <Link to="/">
                            <img src={process.env.PUBLIC_URL + "/assets/images/Logo_bco@8x.png"} alt="logo" className="logo" />
                        </Link>
                        <a href="#" className="side-menu__toggler"><i className="fa fa-bars" /></a>
                    </div>
                    <div className="main-nav__main-navigation">
                        <ul className="main-nav__navigation-box">
                            <li><Link to="/#"><FormattedMessage id='home' defaultMessage={'Inicio'} /></Link></li>
                            <li><Link to="/service"><FormattedMessage id='services' defaultMessage={'Servicios'} /></Link></li>
                            <li><a href="https://consultoriavanren.com/blog/author/admin/"><FormattedMessage id='blog' defaultMessage={'Blog'} /></a></li>
                            <li><Link to="/contact"><FormattedMessage id='contact' defaultMessage={'Contacto'} /></Link></li>
                        </ul>
                    </div>

                </div>
            </nav>
        </header>
    );
};

export default Navbar;
